import { PageProps, useStaticQuery, graphql, navigate } from 'gatsby';
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
// import { StringParam, useQueryParam } from 'use-query-params';
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { useDispatch } from 'react-redux';
import { UserInfo } from '../store/auth/auth.actions';

import { useSessionStorage } from 'usehooks-ts'
import { ALL_STEPS, STEP_COMPLETADO, StepsT, cuentaFetch } from '../utils/fetch';
import { PATH_STEPS } from '../components/Navigator';
import ModalError from '../components/ModalError';

const LayoutPage: React.FC<PageProps> = props => {
  const [errorModal, setErrorModal] = React.useState<string | undefined>(undefined);

  const { children } = props;
  const dispatch = useDispatch();

  const location = useLocation();
  const [userInfo, setUserInfo] = useSessionStorage<UserInfo | undefined>('userInfo', undefined);
  const [_, setUserStep] = useSessionStorage<string | undefined>('userStep', undefined);

  React.useEffect(() => {

    const searchParams = queryString.parse(location.search);
    const {pathname} = props.location
    if (pathname.includes('cuenta-creada')){
      // no hay necesidad de calcular el paso ya que termino el flujo
      // no hay necesidad si se empieza
      setUserStep(undefined);
      setUserInfo(undefined);
      return;
    }
    const { consecutivo } = searchParams;
    const sequence = Array.isArray(consecutivo) ? consecutivo[0] : consecutivo;

    if (sequence && sequence !== userInfo?.sequence) {
      cuentaFetch(`CDIG_CURSOR_PASO`, [sequence]).then((jsonCursorPaso) => {
        const { CDIG_CURSOR_PASOResult: resultCursorPaso } = jsonCursorPaso
        const { codigo, mensaje, datos } = JSON.parse(resultCursorPaso);

        if (codigo != "00") {
          throw Error(mensaje);
        }
        const { Table1: [da] } = datos;
        const serviceStep = da.PASO as StepsT;
        const serviceEmail = da.CORREO as string;

        if (serviceStep === STEP_COMPLETADO){
          throw Error("La solicitud de creación de cuenta ya fue completada. Revise su correo y mensajes en su teléfono móvil. Gracias!");
        }

        setUserInfo({ sequence, email: serviceEmail });
        if (ALL_STEPS.includes(serviceStep)) {
          setUserStep(serviceStep);
          navigate(PATH_STEPS[serviceStep]);
        } else if (pathname !== "/"){
          navigate('/');
        }
      }).catch((err) => {
        if (err instanceof Error) {
          setErrorModal(err.message);
        }
        navigate('/');
      })
    } else if ((!userInfo?.email || !userInfo.sequence) && pathname != "/") {
      navigate('/')
    }
    // TODO delete search params from URL
  }, [location.search, props.location,userInfo]);

  const { logo, line800, bcb, asfi }: { logo: ImageDataLike, line800: ImageDataLike, bcb: ImageDataLike, asfi: ImageDataLike } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 80, quality: 100, layout: CONSTRAINED)
        }
      },
      line800: file(relativePath: { eq: "line-800.png" }) {
        childImageSharp {
          gatsbyImageData(height: 42, quality: 100, layout: CONSTRAINED)
        }
      },
      bcb: file(relativePath: { eq: "bcb.png" }) {
        childImageSharp {
          gatsbyImageData(height: 47, quality: 100, layout: CONSTRAINED)
        }
      },
      asfi: file(relativePath: { eq: "asfi.png" }) {
        childImageSharp {
          gatsbyImageData(height: 39, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `);

  const image = getImage(logo);
  const imageLine800 = getImage(line800);
  const imageBcb = getImage(bcb);
  const imageAsfi = getImage(asfi);
  return (
    <>
      <header className='shadow-md py-2'>
        <div className='flex'>
          <div className='flex-1'>
            {image && <GatsbyImage className='h-12 md:h-16' objectFit='contain' alt='' image={image} />}
          </div>
          {
            userInfo && <div className='hidden min-w-24 pr-6 md:flex items-center text-blue1'>{userInfo.email}</div>
          }

        </div>
      </header>
      <main className='cjn-main '> {children}</main>
      <footer className='bg-green2 text-white text-sm flex items-center justify-center'>
        <div className="py-2">
          <div className="flex flex-row items-center justify-center">
            <div className="text-white font-bold text-xl">
              <a href="tel:33638000">(591-3) 3638000</a>
            </div>
            <div className="text-white text-xl px-4">|</div>
            <div>
              <a href="tel:800109091">
                {imageLine800 && <GatsbyImage className='w-[160px] h-[42px]' objectFit='contain' alt='' image={imageLine800} />}
              </a>
            </div>
          </div>
          <div>{process.env.EPOCHSECONDS}</div>
          {/* <div className="flex flex-row items-center justify-center py-2">
            {imageAsfi && <GatsbyImage className='' objectFit='contain' alt='' image={imageAsfi} />}
            <div className="text-white text-sm px-5 sm:px-10">
              Esta entidad es supervisada por ASFI, calificada por AESA Ratings
            </div>
            {imageBcb && <GatsbyImage className='' objectFit='contain' alt='' image={imageBcb} />}
          </div> */}
        </div>
      </footer>
      <ModalError open={!!errorModal} onClose={() => setErrorModal(undefined)} titulo='Error' mensaje={errorModal} />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default LayoutPage;
