
export const STEP_IDENTIFICACION_DATOSPERSONALES = 'id-datospersonales';
export const STEP_REFERENCIAS = 'referencias';
export const STEP_CUENTA = 'cuenta';
export const STEP_VERIFICAR_IDENTIDAD = 'verficar-identidad';
export const STEP_FINALIZAR = 'finalizar';
export const STEP_COMPLETADO = 'concluido';

export type StepsT = 'id-datospersonales' | 'referencias' | 'cuenta' | 'verficar-identidad' | 'finalizar' | 'concluido';

export const ALL_STEPS: StepsT[] = [STEP_IDENTIFICACION_DATOSPERSONALES, STEP_REFERENCIAS, STEP_CUENTA, STEP_VERIFICAR_IDENTIDAD, STEP_FINALIZAR, STEP_COMPLETADO];

export const cuentaFetch = (serviceName: string, dataArray: (string | number | undefined)[]) => {
  const fetchData = { "Datos": JSON.stringify(dataArray) };
  return fetch(`${process.env.GATSBY_CJN_API_URL}/${serviceName}`, {
    body: JSON.stringify(fetchData),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    // signal: AbortSignal.timeout(360000)
  })
    .then(res => {
      return res.json();
    })
}

const intersoftBaseUrl = 'https://dev.intersoftfintechservices.com:8090/api/ms-digital-identity-profirma'

export const intersoftFetch = (path: string, body?: string | URLSearchParams, method: string = 'POST') => {
  const url = path.startsWith('http') ? path : `${intersoftBaseUrl}/${path}`;
  const iTokenStorage = sessionStorage.getItem("iToken");
  const iToken = iTokenStorage ? JSON.parse(iTokenStorage) : '';
  let headers = {
    Accept: 'application/json',
    Authorization: iToken ? `Bearer ${iToken}` : '',
  };

  if (typeof body === 'string') {
    headers = Object.assign(headers, { 'Content-Type': 'application/json' });
  }

  return fetch(url, {
    body,
    headers,
    method,
  })
    .then(res => {
      return res.json();
    });
}

export interface IntersoftVerifyRegisterResponse {
  message: string;
  data: "VALID" | "INVALID" | null;
  error?: string;
  code: "NOT_FOUND" | "OK" | null;
}

export interface IntersoftOcrResponse {
  message: 'OK' | string;
  data: {
    status: {
      status: {
        transactionId: string;
      }
    },
  };
  error?: string;
  code: "BAD_REQUEST" | "NOT_ACCEPTABLE" | "INTERNAL_SERVER_ERROR" | 200;
  token: string;
}
